import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
} from "react-router-dom";
import { If, Then, Else, Default } from "react-if";
import { SplunkThemeProvider } from "@splunk/themes";
import Publish from "./component/Publish";
import Gallery from "./component/Gallery";
import galleryconfig from "./galleryconfig.json";

function App() {
  return (
    <div className="App">
      <SplunkThemeProvider family="enterprise" colorScheme="dark" density="comfortable">
        <If condition={(galleryconfig.fullMode == "true")}>
          <Then>
            <nav className="navbar navbar-dark fixed-top">
              <div className="container-fluid">
                <Link className="navbar-brand" to="/gallery">
                  {galleryconfig.title}
                </Link>
              </div>
            </nav>
          </Then>
        </If>
        <Router>
          <Switch>
            <Route path="/gallery" component={Gallery} />
            <Route path="/dashboard" component={Gallery} />
            {<Route path="/sign-in" component={Publish} />}
            <Route exact path="/">
              <Redirect to="/gallery" />
            </Route>
          </Switch>
        </Router>
      </SplunkThemeProvider>
    </div>
  );
}

export default App;
