//splunkterns 2023
import dare_to_splunkImg from "./dare_to_splunk_thumb.png";
import impactonjobsImg from "./impactonjobs.png";
import school_safetyImg from "./school_safety.png";
import home_ownershipImg from "./home_ownership.png";
import splunktern_dataImg from "./splunktern_data.png";
import splunk20Img from "./splunk20.png";


//splunkterns 2022
import NoImageImg from "./noimage.png";
import Splunk4HomesImg from "./Splunk4Homes_thumb.png";
import S_TeamImg from "./S_Team_thumb.png";
import Splunk_Rescue_RangersImg from "./Splunk_Rescue_Rangers_thumb.png";
import Splunk4AcceptanceImg from "./Splunk4Acceptance_thumb.png";
import Splunk4ChoicesImg from "./Splunk4Choices_thumb.png";
import Splunk_4_SafetyImg from "./Splunk_4_Safety_thumb.png";
import Splunkers_Undertaking_StarvationImg from "./Splunkers_Undertaking_Starvation_thumb.png";
import Team_JusticeImg from "./Team_Justice_thumb.png";
import The_4_SplunketeersImg  from "./The_4_Splunketeers_thumb.png";
import Splunk_4_LivingImg from "./Splunk_4_Living_thumb.png";

//splunkterns 2021
import air_force_w1nsImg from "./air_force_w1ns.png";
import bucket_fan_clubImg from "./bucket_fan_club.jpg";
import environmental_trailblazersImg from "./environmental_trailblazers.png";
import buttercup_bonfireImg from "./buttercup_bonfire.png";
import eco_splunkImg from "./eco_splunk.png";
import ecoughfriendlyImg from "./ecoughfriendly.png";
import thequaranteamImg from "./thequaranteam.png";
import splunkternsImg  from "./5plunkterns.png";
import asksplunkternImg from "./asksplunktern.png";
import environmental_splunkiesImg  from "./environmental_splunkies.png";
import hugry_splunkersImg from "./hungry_splunkers.png";
import hot_splunk_summerImg  from "./hot_splunk_summer.png";


import splunk4smokeyImg from "./splunk4smokey.png";
import splunk_4_sustainabilityImg  from "./splunk_4_sustainability.png";
import splunk_4_foodImg from "./splunk_4_food.png";
import pwny_expressImg  from "./pwnyexpress.png";
import sweet_home_splunkabamaImg  from "./sweet_home_splunkabama.png";


export default {
  //splunkterns 2023
  dare_to_splunk: dare_to_splunkImg,
  ai_impact_on_jobs: impactonjobsImg,
  school_safety: school_safetyImg,
  home_ownership: home_ownershipImg,
  splunktern_data: splunktern_dataImg,
  splunk2o_search: splunk20Img,

  //splunkterns 2022
  noimage: NoImageImg,
  Splunk4Homes: Splunk4HomesImg,
  S_Team: S_TeamImg,
  Splunk_Rescue_Rangers: Splunk_Rescue_RangersImg,
  Splunk4Acceptance: Splunk4AcceptanceImg, 
  Splunk4Choices: Splunk4ChoicesImg,
  Splunk_4_Safety: Splunk_4_SafetyImg,
  Splunkers_Undertaking_Starvation: Splunkers_Undertaking_StarvationImg,
  Team_Justice: Team_JusticeImg,
  The_4_Splunketeers: The_4_SplunketeersImg,
  Splunk_4_Living: Splunk_4_LivingImg,

  //splunkterns 2021
  environmental_trailblazers:  environmental_trailblazersImg,
  air_force_w1ns: air_force_w1nsImg,
  bucket_fan_club: bucket_fan_clubImg,
  buttercup_bonfire: buttercup_bonfireImg,
  ecosplunk: eco_splunkImg,
  ecoughfriendly: ecoughfriendlyImg,
  the_quaranteam: thequaranteamImg,
  '5plunkterns': splunkternsImg,
  asksplunktern: asksplunkternImg,
  environmental_splunkies: environmental_splunkiesImg,
  hot_splunk_summer: hot_splunk_summerImg,
  hungry_splunkers: hugry_splunkersImg,
  splunk4smokey: splunk4smokeyImg,
  splunk_4_sustainability: splunk_4_sustainabilityImg,
  splunk_4_food: splunk_4_foodImg,
  pwny_express: pwny_expressImg,
  sweet_home_splunkabama: sweet_home_splunkabamaImg,

};