import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import styled from "styled-components";
import { customThemeVariables } from "@splunk/dashboard-ui/customThemeVariables";
import { pick, variables } from "@splunk/themes";
import DashboardCard from "./DashboardCard";
import DashboardViewer from "./DashboardViewer";
import {useLocation} from "react-router-dom";
import { If, Then, Else, Default } from "react-if";

import images from "../img/images";
import galleryconfig from "../galleryconfig.json";
import "../cardstyle.css";

const HeaderWrapper = styled.div`
  padding: 20px;
  display: flex;
  justify-content: flex-end;
`;

const ButtonWrapper = styled.div`
  width: 200px;
`;

const Container = styled.div`
  padding-top: ${galleryconfig.fullMode == "true" ? "60px" : "0px"};
  background: ${customThemeVariables.dashboardBackgroundColor};
  color: ${pick({
    enterprise: {
      light: variables.gray60,
      dark: variables.white,
    },
  })};
`;

const Gallery = () => {
  const search = useLocation().search;
  const name = new URLSearchParams(search).get("view");

  const [dashboardData, setDashboardData] = useState([]);

  const groupFilter = dashboardData.filter(function (item) {
    return item.group.match(galleryconfig.activeList);
  });

  /* const dashboardItem = dashboardData.map((item) => ( */
  const dashboardItem = groupFilter.map((item) => (
  
    <DashboardCard key={item.id} item={item} />
  ));

  // Load the definitions from S3
  useEffect(() => {
    const getDefinitions = async () => {
      //let { ids, definitions, metadata } = await downloadS3();
      const reqJSON = require.context(
        "../dashboards",
        true,
        /(definition|default)\.json/
      );

      const files = reqJSON
        .keys()
        .map((path) => ({ path, file: reqJSON(path) }));

      let ids = [];
      let definitions = [];
      let metadata = [];

      for (let i = 0; i < files.length; i++) {
        if (!ids.includes(files[i].path.split("/")[1])) {
          ids.push(files[i].path.split("/")[1]);
        }
        if (files[i].path.endsWith("definition.json")) {
          definitions.push(files[i].file);
        }
        if (files[i].path.endsWith("default.json")) {
          metadata.push(files[i].file);
        }
      }

      setDashboardData(definitions.map((defn, idx) => ({
          id: ids[idx],
          title: metadata[idx].customTitle ?? defn.title,
          description: metadata[idx].customDescription ?? defn.description,
          image: metadata[idx].customImage ?? images[ids[idx]] ?? images["noimage"],
          definition: defn,
          theme: metadata[idx].theme ?? "dark",
          group: metadata[idx].group ?? "nogroup",
        }))
      );
    };

    getDefinitions();
  }, []);

  return (
    <Container>
      <Router>
        <Switch>
          <Route path="/gallery">
            <If condition={(galleryconfig.fullMode == "true")}>
              <Then>
                <h1>{galleryconfig.title}</h1>
                <div className="dashboards">{dashboardItem}</div>
              </Then>
              <Else>
                <div align="center" class="App-header">Nothing to see here.</div>
              </Else>
            </If>
          </Route>
          <Route path="/dashboard">
            <DashboardViewer dashboards={dashboardData} idfield={`${name}`} />
          </Route>
        </Switch>
      </Router>
    </Container>
  );
};

export default Gallery;
