import { useState, useEffect } from "react";
import https from "https";
import { Buffer } from "buffer";
import { XmlDocument } from 'xmldoc';
import galleryconfig from "../galleryconfig.json"

const fs = require('fs');
const path = require('path');
const querystring = require('querystring');
const fetch = require('node-fetch');
const debug = require('debug')('datasnapshot');

const greenCheck = <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 128 128" width="64px" height="64px"><path fill="#fff" d="M64 16A48 48 0 1 0 64 112A48 48 0 1 0 64 16Z"/><path fill="#8ccfb9" d="M64 25A39 39 0 1 0 64 103A39 39 0 1 0 64 25Z"/><path fill="none" stroke="#444b54" stroke-miterlimit="10" stroke-width="6" d="M64 16A48 48 0 1 0 64 112A48 48 0 1 0 64 16Z"/><path fill="none" stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" stroke-width="6" d="M42 69L55.55 81 86 46"/></svg>
const redCheck = <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 128 128" width="64px" height="64px"><path fill="#fff" d="M64 16A48 48 0 1 0 64 112A48 48 0 1 0 64 16Z"/><path fill="#cf8ca2" d="M64 25A39 39 0 1 0 64 103A39 39 0 1 0 64 25Z"/><path fill="none" stroke="#444b54" stroke-miterlimit="10" stroke-width="6" d="M64 16A48 48 0 1 0 64 112A48 48 0 1 0 64 16Z"/><path fill="none" stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" stroke-width="6" d="M42 69L55.55 81 86 46"/></svg>
const greyCheck = <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 128 128" width="64px" height="64px"><path fill="#fff" d="M64 16A48 48 0 1 0 64 112A48 48 0 1 0 64 16Z"/><path fill="#444b54" d="M64 25A39 39 0 1 0 64 103A39 39 0 1 0 64 25Z"/><path fill="none" stroke="#444b54" stroke-miterlimit="10" stroke-width="6" d="M64 16A48 48 0 1 0 64 112A48 48 0 1 0 64 16Z"/><path fill="none" stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" stroke-width="6" d="M42 69L55.55 81 86 46"/></svg>



const seenImages = {};

const qs = (obj) =>
  Object.entries(obj)
    .map(
      ([name, value]) =>
        `${encodeURIComponent(name)}=${encodeURIComponent(value)}`
    )
    .join("&");

const listDashboards = async ({
  url = "",
  username = "",
  password = "",
} = {}) => {
  
  try
  { 
    const res = await fetch(
    `${url}/servicesNS/-/${encodeURIComponent("-")}/data/ui/views?${qs({
      output_mode: "json",
      count: 0,
      offset: 0,
      search: `(isDashboard=1 AND isVisible=1 AND (version=2))`,
    })}`,
    {
      method: "GET",
      headers: {
        Authorization: `Basic ${Buffer.from(`${username}:${password}`).toString(
          "base64"
        )}`,
      },
      agent: new https.Agent({
        rejectUnauthorized: false,
      }),
    }
  );
 
  
  const body = await res.json();

  return body.entry.map((entry) => ({
    label: entry.name,
    value: entry.name,
    app: entry.content["eai:appName"],
  }));
}
catch (e)
{
  setCurrentCheck(redCheck)
  setCurrentStatus("Failed to load Dashboard" + res.statusText)
  return "Error"
}
};

async function loadDashboard(name,
  app, {
    url = "",
    username = "",
    password = "",
  }) {
  const res = await fetch(
    `${url}/servicesNS/-/${encodeURIComponent(app)}/data/ui/views/${encodeURIComponent(name)}?output_mode=json`,
    {
      method: "GET",
      headers: {
        Authorization: `Basic ${Buffer.from(`${username}:${password}`).toString(
          "base64"
        )}`,
      },
      agent: new https.Agent({
        rejectUnauthorized: false,
      }),
    }
  );
  const body = await res.json()
  return body;
};

async function downloadImage(src, assetType, app, splunkdInfo) {
  if (!src) {
    return src;
  }
  if (src in seenImages) {
    return seenImages[src];
  }

  if (src.startsWith("data:image"))
  {
    return src;
  }
  const [type, id] = src.split('://');

  // Need to improve this function so we actually are embedding these images
  if (type === 'https' || type === 'http') {
    const res = await fetch(src);

    const data = await res.buffer();
    const mimeType = res.headers.get('Content-Type');

    return src;
  }

  if (type === 'splunk-enterprise-kvstore') {

    const imgData = await getImage(app, assetType, id, splunkdInfo)

    const [mimeType, data] = parseDataUri(imgData.dataURI);

    return imgData.dataURI
  }

  throw new Error(`Unexpected image type: ${type}`);
}

function parseDataUri(dataUri) {
  if (!dataUri.startsWith('data:')) {
    throw new Error('Invalid data URI');
  }
  const semiIdx = dataUri.indexOf(';');
  if (semiIdx < 0) {
    throw new Error('Invalid data URI');
  }
  const mime = dataUri.slice(5, semiIdx);
  if (!dataUri.slice(semiIdx + 1, 7) === 'base64,') {
    throw new Error('Unsupported data URI encoding');
  }
  const data = Buffer.from(dataUri.slice(semiIdx + 8), 'base64');
  return [mime, data];
}

const getImage = async (
  app,
  assetType,
  id, {
    url = "",
    username = "",
    password = "",
  } = {}) => {
  const res = await fetch(
    `${url}/servicesNS/nobody/${encodeURIComponent(app)}/storage/collections/data/splunk-dashboard-${assetType}/${encodeURIComponent(
      id
    )}`,
    {
      method: "GET",
      headers: {
        Authorization: `Basic ${Buffer.from(`${username}:${password}`).toString(
          "base64"
        )}`,
      },
      agent: new https.Agent({
        rejectUnauthorized: false,
      }),
    }
  );
  
  const body = await res.json();
  if (!res.ok) {
    setCurrentCheck(redCheck)
    setCurrentStatus("Failed to get image" + res.statusText)
  } 
  return body
};

async function generateDashboard({ name, targetName = name, app, kvstoreApp, projectFolder }, splunkdInfo, snapshotBool, dash) {

  /* eslint-disable  no-await-in-loop */
  for (const viz of Object.values(dash.visualizations || {})) {
    try {
      if (viz.type === 'viz.singlevalueicon') {
        // console.log("Found Single Value Icon")
        viz.options.icon = await downloadImage(viz.options.icon, 'icons', kvstoreApp, splunkdInfo);
      }
      if (viz.type === 'viz.img') {
        // console.log("Found IMG")
        viz.options.src = await downloadImage(viz.options.src, 'images', kvstoreApp, splunkdInfo);
      }
      if (viz.type === 'splunk.choropleth.svg') {
        // console.log("Found Choropleth")

        viz.options.svg = await downloadImage(viz.options.svg, 'images', kvstoreApp, splunkdInfo);
      }
      if (viz.type === 'viz.choropleth.svg') {
        // console.log("Found SVG")

        viz.options.svg = await downloadImage(viz.options.svg, 'images', kvstoreApp, splunkdInfo);
      }
    } catch (e) {
      console.log("Failed to load image with src: " + viz.options.src)
      // setCurrentCheck(redCheck)
      // setCurrentStatus("Failed to get image for visualization: " + viz )
      // console.error(`Failed to download image ${viz.options.icon || viz.options.src || viz.options.svg}`, e);
    }
  }
  /* eslint-enable  no-await-in-loop  */
  /* eslint-disable  no-param-reassign  */

  if (dash.layout.options.backgroundImage) {
    dash.layout.options.backgroundImage.src = await downloadImage(
      dash.layout.options.backgroundImage.src,
      'images',
      kvstoreApp,
      splunkdInfo,
      projectFolder
    );
  }

const qualifiedSearchString = query => (query.trim().startsWith('|') ? query : `search ${query}`);
const sleep = ms => new Promise(r => setTimeout(r, ms));

async function fetchData(search, { id, app, refresh, splunkdUrl, splunkUser, splunkPassword }) {
    const log = require('debug')(`ds:${id}`);

    const agent = splunkdUrl.startsWith('https')
        ? new (require('https').Agent)({
              rejectUnauthorized: false,
          })
        : undefined;

    log('Executing search for data fn', id);
    const SERVICE_PREFIX = `servicesNS/${encodeURIComponent(splunkUser)}/${encodeURIComponent(app)}`;

    const response = await fetch(`${splunkdUrl}/${SERVICE_PREFIX}/search/jobs`, {
        method: 'POST',
        headers: {
            Authorization: `Basic ${Buffer.from([splunkUser, splunkPassword].join(':')).toString('base64')}`,
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: querystring.stringify({
            output_mode: 'json',
            earliest_time: (search.options.queryParameters || {}).earliest,
            latest_time: (search.options.queryParameters || {}).latest,
            search: qualifiedSearchString(search.options.query),
            timeout: 120,
        }),
        agent,
    });

    if (response.status > 299) {
        console.log("Error with Search" + search.options.query)
    }
    const { sid } = await response.json();
    log(`Received search job sid=${sid} - waiting for job to complete`);

    let complete = false;
      /* eslint-disable  no-await-in-loop */

    while (!complete) {
        const statusData = await fetch(`${splunkdUrl}/${SERVICE_PREFIX}/search/jobs/${encodeURIComponent(sid)}?output_mode=json`, {
            headers: {
                Authorization: `Basic ${Buffer.from([splunkUser, splunkPassword].join(':')).toString('base64')}`,
            },
            agent,
        }).then(r => r.json());

        if (!statusData.entry) 
        {
            complete = true
        }
        if (statusData.entry) 
        {
        const jobStatus = statusData.entry[0].content;
        
        
        if (jobStatus.isFailed) {
            throw new Error('Search job failed');
        }
        complete = jobStatus.isDone;
        if (!complete) {
            await sleep(250);
        }
    }
    }

    log('Search job sid=%s for data fn id=%s is complete', sid, id);
  /* eslint-enable  no-await-in-loop */

    const resultsQs = querystring.stringify({
        output_mode: 'json_cols',
        count: 10000,
        offset: 0,
        search: search.postprocess,
    });
    const data = await fetch(`${splunkdUrl}/${SERVICE_PREFIX}/search/jobs/${sid}/results?${resultsQs}`, {
        method: 'GET',
        headers: {
            Authorization: `Basic ${Buffer.from([splunkUser, splunkPassword].join(':')).toString('base64')}`,
        },
        agent,
    }).then(r => r.json());

    return data;
}

async function takeDataSnapshot(splunkdInfo, name, dash, app) {

    const baseOptions = {
        splunkdUrl: splunkdInfo.url,
        splunkUser: splunkdInfo.username,
        splunkPassword: splunkdInfo.password,
    };

    const seen = new Set();
                        for (const viz of Object.values(dash.visualizations)) {
                            if (viz.dataSources) {
                                for (const dsid of Object.values(viz.dataSources)) {
                                    if (!seen.has(dsid)) {
                                        seen.add(dsid);
                                    }
                                }
                            }
                        }
    if (!baseOptions.splunkPassword) {
        throw new Error('SPLUNKD_PASSWORD environment variable not set');
    }

    const allData = {};
    for (let i = 0; i < Object.keys(dash.dataSources).length; i += 10) {
        const chunk = Object.keys(dash.dataSources).slice(i, i + 10);
        // debug('Processing chunk idx=%d len=%d', i, chunk.length);
          /* eslint-disable  no-await-in-loop, no-loop-func */
        
        const results = await Promise.all(
            chunk.map(id =>
                (async () => {
                    const search = dash.dataSources[id];

                    let data = []
                    if (search.type == 'ds.test')
                    {
                      data = search.options.data
                    }
                    if (seen.has(id) && search.type=='ds.search')
                    {
                      data = await fetchData(search, { id, app, ...baseOptions, refresh: 400 });

                    return { id, data };
                    }
                    else if (seen.has(id) && search.type=='ds.test')
                    {
                      return { id, data};
                    }
                })()
            )
        );
        var finalResults = []
        for(let v in results){
            if (results[v])
            {
                finalResults.push(results[v])
            }
        }
          /* eslint-enable  no-await-in-loop, no-loop-func */

        for (const { id, data } of finalResults) {
            allData[id] = data;
        }
    }
    return allData

}

async function clearSnapshot(projectRoot) {
    fs.writeFileSync(path.join(projectRoot, '_snapshot.json'), '{}', { encoding: 'utf-8' });
}

  const snapshots = await takeDataSnapshot(splunkdInfo, name, dash, app);
  for (let ds in dash.dataSources) {
    for (let ds_snap in snapshots) {
      if (ds === ds_snap) {
        dash.dataSources[ds].type = "ds.test"
        dash.dataSources[ds].options = {}
        dash.dataSources[ds].options.data = {}
        dash.dataSources[ds].options.meta = {}
        dash.dataSources[ds].options.data.columns = snapshots[ds_snap].columns

        var fields = []
        for (let field in snapshots[ds_snap].fields) {
          fields.push({ "name": snapshots[ds_snap].fields[field]})
        }
        dash.dataSources[ds].options.data.fields = fields
      }
    }
  }
  return dash
}

const PublishForm = ({ url, username, password }) => {
  const [currentCheck, setCurrentCheck] = useState('');
  const [currentStatus, setCurrentStatus] = useState('');
  const [formValues, setFormValues] = useState({
    dashboardId: "",
    publicName: "",
  });
  const [dashboards, setDashboards] = useState([]);

  useEffect(() => {
    try{
    const getDashboards = async () => {
      const dashboardList = await listDashboards({ url, username, password })
      if (dashboardList == "Error")
      {
        setCurrentCheck(redCheck)
        setCurrentStatus("Failed to Login")
      }
      else
      {
        //console.log("Setting Dashboards")
      setDashboards(dashboardList);
      }
      
    };
    getDashboards();
  }
  catch (e){
    throw "Cannot Get Dashboards"
  }
  }, [url, username, password]);

  const handleSubmit = async event => {
    event.preventDefault();


    const app = formValues.dashboardId.split(":")[1]
    const dashboardId = formValues.dashboardId.split(":")[0]
    let dashXML = await loadDashboard(dashboardId, app, { url: url, username: username, password: password });
    dashXML = dashXML["entry"][0].content["eai:data"]
    const snap = "Y"
    const targetName = dashboardId;

    const doc = new XmlDocument(dashXML);
    const def = JSON.parse(doc.childNamed('definition').val);
    const theme = doc.attr['theme'];

    const dashboardInfo = await generateDashboard(
      {
        name: targetName,
        targetName,
        app: "search",
        kvstoreApp: "splunk-dashboard-studio",
      },
      { url: url, username: username, password: password },
      snap,
      def
    );
    

    const metadata = {
      theme:theme
    }

    
    // Need to gather any custom image or title. If they aren't null, add them to the metadata


    if (formValues.customImage){
      metadata.customImage = formValues.customImage
    }

    // Check for a custom title. If one exists in form use that. If not, use the dashboard title. 

    if (formValues.customTitle){
      metadata.customTitle = formValues.customTitle
    }
    else{
      metadata.customTitle = dashboardInfo.title
    }

    // Check for a custom description. If one exists in form use that. If not, use the dashboard description. 
    if (formValues.customDescription){
      metadata.customDescription = formValues.customDescription
    }
    else{
      metadata.customDescription = dashboardInfo.description

    }

    /* Here is where we upload to S3 */
    try{
      if(galleryconfig.storageLocation == "s3")
      {
    //uploadS3(targetName,'definition.json',dashboardInfo)
    //uploadS3(targetName,'default.json', metadata)
      }
      else
      {

      }
    setCurrentCheck(greenCheck)
    setCurrentStatus("Upload to S3 Successful")
  }
  catch (e)
  {
    setCurrentCheck(redCheck)
    console.log(e)
    setCurrentStatus("Failed to Upload to S3")
    throw "Failed save files"
  } 
  };

  const handleFormChange = (event) => {
    const { name, value } = event.target;
    if(name=="dashboardId")
    {
      setCurrentCheck(greyCheck)
      setCurrentStatus("")
    }
    setFormValues({ ...formValues, [name]: value });
  };

  return (
    <form onSubmit={handleSubmit.bind(this)}>
      <h3>Use the Form to Create a Dashboard</h3>
      <div className="form-group">
        <label htmlFor="true">Dashboard ID</label>
        <select
          defaultValue="Select a dashboard..."
          type="string"
          name="dashboardId"
          className="form-control"
          onChange={handleFormChange}
        >

          {dashboards.map(({ label, value, app }) => (
            <option key={value} value={`${value}:${app}`}>
              {label}
            </option>
          ))}
        </select>
      </div>
      <div className="form-group">
        <label htmlFor="true">
          Enter a Public Name for the dashboard (optional)
        </label>
        <input
          type="string"
          name="customTitle"
          className="form-control"
          onChange={handleFormChange}
          placeholder=""
        />
         <label htmlFor="true">
          Enter a Custom Image URL (Recommend 370x200px) (optional)
        </label>
         <input
          type="string"
          name="customImage"
          className="form-control"
          onChange={handleFormChange}
        />
        <label htmlFor="true">
          Enter a Custom Text Description (Markdown Supported) (optional)
        </label>
         <textarea
          cols="40" 
          rows="5"
          type="string"
          name="customDescription"
          className="form-control"
          onChange={handleFormChange}
        />
      </div>
      <div className="check-boxes">
        <p>{currentStatus}</p>{currentCheck}

      </div>
      <div className="form-group">
        <label htmlFor="true">Public URL: &nbsp;</label>
        <a
          href={`http://localhost:3000/gallery/${formValues.dashboardId.split(":")[0]}`}
          target="_blank"
          rel="noreferrer"
        >
          {`http://localhost:3000/gallery/${formValues.dashboardId.split(":")[0]}`}
        </a>
      </div>
      <button type="submit" className="btn btn-dark btn-block">
        Submit &gt;
      </button>
    </form>
  );
};

export default PublishForm;
