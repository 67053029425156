import { useState } from "react";
import Login from "./LogIn";
import PublishForm from "./PublishForm";
import https from "https";

const Publish = () => {
  const [publishData, setPublishData] = useState();
  const greenCheck = <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 128 128" width="64px" height="64px"><path fill="#fff" d="M64 16A48 48 0 1 0 64 112A48 48 0 1 0 64 16Z"/><path fill="#8ccfb9" d="M64 25A39 39 0 1 0 64 103A39 39 0 1 0 64 25Z"/><path fill="none" stroke="#444b54" stroke-miterlimit="10" stroke-width="6" d="M64 16A48 48 0 1 0 64 112A48 48 0 1 0 64 16Z"/><path fill="none" stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" stroke-width="6" d="M42 69L55.55 81 86 46"/></svg>
const redCheck = <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 128 128" width="64px" height="64px"><path fill="#fff" d="M64 16A48 48 0 1 0 64 112A48 48 0 1 0 64 16Z"/><path fill="#cf8ca2" d="M64 25A39 39 0 1 0 64 103A39 39 0 1 0 64 25Z"/><path fill="none" stroke="#444b54" stroke-miterlimit="10" stroke-width="6" d="M64 16A48 48 0 1 0 64 112A48 48 0 1 0 64 16Z"/><path fill="none" stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" stroke-width="6" d="M42 69L55.55 81 86 46"/></svg>
const greyCheck = <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 128 128" width="64px" height="64px"><path fill="#fff" d="M64 16A48 48 0 1 0 64 112A48 48 0 1 0 64 16Z"/><path fill="#444b54" d="M64 25A39 39 0 1 0 64 103A39 39 0 1 0 64 25Z"/><path fill="none" stroke="#444b54" stroke-miterlimit="10" stroke-width="6" d="M64 16A48 48 0 1 0 64 112A48 48 0 1 0 64 16Z"/><path fill="none" stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" stroke-width="6" d="M42 69L55.55 81 86 46"/></svg>

const [currentCheck, setCurrentCheck] = useState('');
const [currentStatus, setCurrentStatus] = useState('');

  const qs = (obj) =>
  Object.entries(obj)
    .map(
      ([name, value]) =>
        `${encodeURIComponent(name)}=${encodeURIComponent(value)}`
    )
    .join("&");
  const handleLogin = async (event, { url, username, password }) => {

    event.preventDefault(); 
    try
    {
    const res = await fetch(
    `${url}/servicesNS/-/${encodeURIComponent("-")}/data/ui/views?${qs({
      output_mode: "json",
      count: 0,
      offset: 0,
      search: `(isDashboard=1 AND isVisible=1 AND (version=2))`,
    })}`,
    {
      method: "GET",
      headers: {
        Authorization: `Basic ${Buffer.from(`${username}:${password}`).toString(
          "base64"
        )}`,
      },
      agent: new https.Agent({
        rejectUnauthorized: false,
      }),
    }
  );
 
  
  const body = await res.json();
    setPublishData({ url, username, password });
  }
  catch (e)
  {
    setCurrentCheck(redCheck)
    setCurrentStatus("Login Failed. Please verify credentials are correct and that you have a valid certificate. ")
  }
  };

  return (
    <div className="auth-wrapper">
      <div className="auth-inner">
        {publishData ? (
          <PublishForm
            url={publishData.url}
            username={publishData.username}
            password={publishData.password}
          />
        ) : (
          <Login onSubmit={handleLogin} check={currentCheck} status={currentStatus}/>
                  )}
      </div>
    </div>
  );
};

export default Publish;
