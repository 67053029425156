/* eslint-disable */
import React, { Component } from "react";


// eslint-disable-next-line react/prefer-stateless-function
export default class Login extends Component {
    constructor(props) {
        super(props);
        this.state = { url: "", username: "", password: "" }

        this.handleChange = this.handleChange.bind(this)
        this.eventSubscribe = this.eventSubscribe.bind(this)
    }

    handleChange(event) {
        const { name, value } = event.target;
        this.setState({ [name]: value });
    }

    async eventSubscribe(event) {
        event.preventDefault();

        this.props.onSubmit(event, this.state);
    }


    render() {
        return (
            <form onSubmit={this.eventSubscribe}>
                <h3>Sign In</h3>

                <h5>Sign in to publish a dashboard</h5>

                <div className="form-group">
                    <label id="splunk_url">Enter your Splunk URL</label>
                    <input type="string" name="url" className="form-control" placeholder="https://localhost:8089" onChange={this.handleChange} />
                </div>

                <div className="form-group">
                    <label id="username">Username</label>
                    <input type="string" name="username" className="form-control" placeholder="" onChange={this.handleChange} />
                </div>

                <div className="form-group">
                    <label id="password">Password</label>
                    <input type="password" name="password" className="form-control" placeholder="" onChange={this.handleChange} />
                </div>

                <button type="submit" className="btn btn btn-dark btn-block">Sign In</button>
                {this.props.check}
                <p>{this.props.status}</p>
            </form>
        );
    }
}