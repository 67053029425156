import styled from "styled-components";
import DashboardCore from "@splunk/dashboard-core";
import DashboardPresets from "@splunk/dashboard-presets/CloudViewOnlyPreset";
import Button from "@splunk/react-ui/Button";
import SplunkThemeProvider from "@splunk/themes/SplunkThemeProvider";
import { DashboardContextProvider } from "@splunk/dashboard-context";
import { GeoJsonProvider, GeoRegistry } from "@splunk/dashboard-context";
import React, { Suspense, useMemo, useEffect, useRef } from "react";
import { If, Then, Else, Default } from "react-if";
import galleryconfig from "../galleryconfig.json";

import { useHistory } from "react-router-dom";

const Container = styled.div`
  height: 100%;
  width: 100%;
  box-sizing: border-box;
`;

const ButtonWrapper = styled.div`
  width: 200px;
`;

const HeaderWrapper = styled.div`
  padding: 20px;
  display: flex;
  justify-content: flex-start;
`;

const themeToVariant = {
  enterprise: { colorScheme: "light", family: "enterprise" },
  enterpriseDark: { colorScheme: "dark", family: "enterprise" },
  prisma: { colorScheme: "dark", family: "prisma" },
};

const DashboardViewer = ({ dashboards, idfield }) => {
  // const { id: dashboardId } = useParams();
  const history = useHistory();

  const dashboardId = idfield;
  if (dashboardId == "null") {
    history.push(`/gallery`);
  }
  const geoRegistry = useMemo(() => {
    const geoRegistry = GeoRegistry.create();
    geoRegistry.addDefaultProvider(new GeoJsonProvider());
    return geoRegistry;
  }, []);

  const dashboard = dashboards.find(({ id }) => id === dashboardId);
  if (!dashboard) {
    return null;
  }

  const { title } = dashboard;
  const theme = dashboard.theme;
  const definition = {
    ...dashboard.definition,
    layout: {
      ...dashboard.definition.layout,
      options: {
        ...dashboard.definition.layout.options,
        showTitleAndDescription: false,
      },
    },
  };

  console.log(dashboard.definition);

  return (
    <Container>
      <If condition={galleryconfig.fullMode == "true"}>
        <Then>
          <HeaderWrapper>
            <ButtonWrapper>
              <Button
                to="/gallery"
                appearance="flat"
                label="< Gallery"
                inline={false}
              />
            </ButtonWrapper>
            {/*<ButtonWrapper>
          <Button
            to="/sign-in"
            appearance="flat"
            label="Share Your Data Story >"
            inline={false}
          />
        </ButtonWrapper> */}
          </HeaderWrapper>
          <h1>{title}</h1>
        </Then>
      </If>
      <SplunkThemeProvider colorScheme={theme} family="enterprise">
        <DashboardContextProvider
          initialDefinition={dashboard.definition}
          geoRegistry={geoRegistry}
          initialMode="view"
          featureFlags={{
            enableSvgHttpDownloader: true,
            enableSmartSourceDS: true,
            enableTokensInUrl: false,
          }}
          preset={DashboardPresets}
        >
          <Suspense fallback={<p>Loading</p>}>
            <DashboardCore height="100%" width="100%" />
          </Suspense>
        </DashboardContextProvider>
      </SplunkThemeProvider>
    </Container>
  );
};

export default DashboardViewer;
