import { useHistory } from "react-router-dom";
import Card from "@splunk/react-ui/Card";
import P from "@splunk/react-ui/Paragraph";
import Button from "@splunk/react-ui/Button";
import ReactMarkdown from 'react-markdown';
import React, { useState, useEffect } from "react";

function DashboardCard(props) {
  const history = useHistory();
  const style = { width: 370, height: 700, margin: "0 20px 20px 0", display: 'block', marginLeft: 'auto', marginRight: 'auto'  };


  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  
  const handleClick = () => {
    // history.push(`/dashboard?view=${props.item.id}`);

  };


  return (
    <div className="dashboard">
      <Card style={style}>
        <Card.Header title={""} subtitle={""} />
        <a href={`/dashboard?view=${props.item.id}`}><img onClick={handleClick} style={{cursor:'pointer',  objectFit: 'contain', maxWidth: '330px', maxHeight: '180px', width: 'auto', height: 'auto',  display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={props.item.image} alt="dashboard" /></a>
        <Card.Body style={{cursor:'pointer', height: '375px', width: '367px'}}>
          <P> </P>
          <ReactMarkdown>{props.item.description}</ReactMarkdown>
          {/* <div>{props.item.group}</div> */}
        </Card.Body>
        <Card.Footer showBorder={false} style={{cursor:'pointer',  objectFit: 'contain', maxWidth: '330px', maxHeight: '180px', width: 'auto', height: 'auto',  display: 'block', marginLeft: 'auto', marginRight: 'auto' }}>
        <a href={`/dashboard?view=${props.item.id}`}><Button label="View"/></a>
        </Card.Footer>
      </Card>
    </div>
  );
}

export default DashboardCard;
